/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import './styles/app.scss';

// start the Stimulus application
// import './bootstrap.js';

// const $ = require('jquery'); 
// global.$ = global.jQuery = $;

// require('bootstrap');
// require('@fortawesome/fontawesome-free/css/all.min.css');
// require('@fortawesome/fontawesome-free/js/all.js');

import './styles/bootstrap.min.css';
import './styles/flaticon.css';
import './styles/css/mega-menu/mega_menu.css';
import './styles/font-awesome.min.css';
// import './styles/css/owl-carousel/owl.carousel.css';
import './styles/css/magnific-popup/magnific-popup.css';
// import './styles/css/revolution/css/settings.css';
import './styles/style.css';
import './styles/responsive.css';

// $(function() {
//     $('#loading').fadeOut(500);
// });